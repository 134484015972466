import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import HelpModal from "./HelpModal"; // Import your HelpModal component
import './Home.css';

// Images for the carousel
import slide2 from "../src/image/c1.png";
import slide5 from "../src/image/23.png";
import slide6 from "../src/image/24.png";
import slide7 from "../src/image/25.png";
import slide8 from "../src/image/26.png";
import slide9 from "../src/image/27.png";
import slide10 from "../src/image/28.png";

const Home = () => {
  const [showHelp, setShowHelp] = useState(false); // State to manage HelpModal visibility

  const handleOpenHelp = () => setShowHelp(true); // Function to open HelpModal
  const handleCloseHelp = () => setShowHelp(false); // Function to close HelpModal

  // Carousel images data
  const carouselItems = [
    { src: slide2, alt: "First slide" },
    { src: slide5, alt: "Second slide" },
    { src: slide6, alt: "Third slide" },
    { src: slide7, alt: "Fourth slide" },
    { src: slide8, alt: "Fifth slide" },
    { src: slide9, alt: "Sixth slide" },
    { src: slide10, alt: "Seventh slide" },
  ];

  // Reasons to choose the service
  const reasons = [
    {
      title: 'Experienced and skilled professionals',
      description: 'Our team consists of experienced experts who are dedicated to delivering top-notch service.'
    },
    {
      title: 'Competitive pricing',
      description: 'We offer competitive pricing without compromising the quality of our services.'
    },
    {
      title: 'Timely and reliable service',
      description: 'We value your time and strive to provide reliable services within the agreed timeframe.'
    },
    {
      title: 'Personalized solutions tailored to your needs',
      description: 'We provide customized solutions that are designed to meet your specific requirements.'
    }
  ];

  const handleRedirect = () => {
    window.location.href = "/download"; // Redirect to download page
  };

  return (
    <>
      {/* Carousel Section */}
      <Carousel indicators={false} fade interval={3000} controls={true}>
        {carouselItems.map((item, index) => (
          <Carousel.Item key={index}>
            <img className="d-block w-100" src={item.src} alt={item.alt} loading="lazy" />
            {index > 0 && ( // Show button only for items after the first
              <Carousel.Caption>
                <button className="carousel-button" onClick={handleRedirect}>
                  Get Services
                </button>
              </Carousel.Caption>
            )}
          </Carousel.Item>
        ))}
      </Carousel>

      {/* Mission Section */}
      <section className="mission-section">
        <div className="mission-text">
          <h2>Our Mission</h2>
          <p>
            We integrate systems and technologies designed by our team of scientists and engineers,
            with the most advanced technologies available to deliver more reliable and efficient energy projects.
            Our global platform is our strength.
          </p>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <div className="why-choose-us-section">
        <h2 className="section-title">Why Choose Us?</h2>
        <p className="section-subtitle">Here are a few reasons to choose HomeLyf Services:</p>
        <div className="cards-container">
          {reasons.map((reason, index) => (
            <div key={index} className="card">
              <h3 className="card-title">{reason.title}</h3>
              <p className="card-description">{reason.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Help Button */}
      <button className="help-button" onClick={handleOpenHelp}>
        Help
      </button>

      {/* Help Modal */}
      <HelpModal show={showHelp} handleClose={handleCloseHelp} />
    </>
  );
};

export default Home;
