import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import playstore from '../src/image/download-playStore.png';
import appstore from '../src/image/download-appStore.png';
import mobilemockup from '../src/image/mock.png';
import d1 from '../src/image/m1.png';
import d2 from '../src/image/m2.png';
import d3 from '../src/image/m3.png';
import d4 from '../src/image/m4.png';
import d5 from '../src/image/m5.png';
import d6 from '../src/image/m6.png';
import d7 from '../src/image/m7.png';
import HelpModal from "./HelpModal"; // Import HelpModal
import './Download.css';

const Download = () => {
  const [showHelp, setShowHelp] = useState(false); // State for help modal visibility

  const handleOpenHelp = () => {
    setShowHelp(true); // Show help modal
  };

  const handleCloseHelp = () => {
    setShowHelp(false); // Close help modal
  };

  return (
    <>
      <div className="app">
        <div className="content">
          <div className="mobile-view">
            <h1>Helping Dreamers Become Doers</h1>
            <p>
              "HomeLyf Services is dedicated to providing a diverse array of home services tailored to meet your unique needs. Our team of professionals is committed to delivering exceptional quality with a personal touch."
            </p>
          </div>

          <div className="download-buttons">
            <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
              <img src={playstore} alt="Get it on Google Play" />
            </a>
            <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
              <img src={appstore} alt="Download on the App Store" />
            </a>
          </div>
        </div>

        <div className="mockup">
          <img src={mobilemockup} alt="Mobile Mockup" />
        </div>
      </div>

      <div className="main-container my-4">
        <div className="row align-items-start">
          {/* Left Side: Carousel */}
          <div className="col-md-6">
            <div className="carousel-container">
              <Carousel className="transparent-carousel rounded" indicators>
                <Carousel.Item>
                  <img className="d-block w-100" src={d1} alt="First slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={d2} alt="Second slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={d3} alt="Third slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={d4} alt="Fourth slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={d5} alt="Fifth slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={d6} alt="Sixth slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={d7} alt="Seventh slide" />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>

          {/* Right Side: Text Box */}
          <div className="col-md-6 text-box p-4 shadow rounded">
            <h2 className="text-center">Experience Hassle-Free Home Services</h2>
            <p>
              "All Your Home Needs in One Place,  
              Expert Help Just a Tap Away.  
              Quality Service with a Personal Touch,  
              Download HomeLyf App Today!"
            </p>
          </div>
        </div>
      </div>

      {/* Help Button */}
      <div className="text-center my-4">
        <button className="help-button" onClick={handleOpenHelp}>
          Help
        </button>
      </div>

      {/* Help Modal */}
      <HelpModal show={showHelp} handleClose={handleCloseHelp} />
    </>
  );
};

export default Download;
