import React from "react";


import './Download.css';


const TermsConditions = () => {
  return (
    <>
     <div
        style={{
          padding: '50px',
          maxWidth: '1200px',
          margin: 'auto',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          color: '#333',
          textAlign: 'justify' // Justified text alignment
        }}
      ></div>
     <div style={{ padding: '50px', maxWidth: '1200px', margin: 'auto', fontFamily: 'Arial, sans-serif', fontSize: '14px', color: '#333' }}>
      <h2 style={{ color: '#E62188' }}>Terms and Conditions for Service Providers</h2>
      <p><strong>Effective Date: 26/09/2024</strong></p>

      <p style={{ textAlign: 'justify' }}>
      Welcome to Qdtas Technologies Pvt. Ltd. (“Qdtas Technologies Pvt. Ltd.,” “we,” “us,” or “our”). These Terms and Conditions ("Terms") govern your access to and use of our website 
          <a href="https://www.qdtas.com/"> https://www.qdtas.com/</a> 
          and our mobile application (‘Qdtas Technologies Pvt. Ltd.’) (collectively, the “Platform”) as a Service Provider. By registering as a Service Provider on the Platform, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Platform.
      </p>

      <h3>1. Definitions</h3>
        <ul>
          <li>“Service Provider” or “you” refers to any individual or entity registered to offer professional services through the Platform.</li>
          <li>“User” refers to any individual accessing or using the Platform to seek services.</li>
          <li>“Services” refers to the services provided by Qdtas Technologies Pvt. Ltd. via the Platform.</li>
          <li>“Professional Services” refers to the services provided by Service Providers through the Platform.</li>
        </ul>
      
      <h3>2. Acceptance of Terms</h3>
      <p style={{ textAlign: 'justify' }}>
      By registering as a Service Provider, you agree to comply with and be legally bound by these Terms, our Privacy Policy, and all applicable laws and regulations.</p>
      

      <h3>3. Modifications to Terms</h3>
      <p style={{ textAlign: 'justify' }}>
      We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting on the Platform. Your continued use of the Platform after any changes indicates your acceptance of the modified Terms. We recommend you review these Terms periodically.
      </p>

      <h3>4. Registration and Account</h3>
<p style={{ textAlign: 'justify' }}><strong>(a) Eligibility:</strong> To register as a Service Provider, you must be legally capable of entering into binding contracts and meet any other eligibility requirements set by Qdtas Technologies Pvt. Ltd.</p>
<p style={{ textAlign: 'justify' }}><strong>(b) Account Creation:</strong> You agree to provide accurate and complete information during the registration process and to update such information as necessary. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. Notify us immediately of any unauthorized use of your account.</p>
<p style={{ textAlign: 'justify' }}><strong>(c) Account Termination:</strong> We reserve the right to terminate or suspend your account at any time for any reason, including if you breach these Terms.</p>
<p style={{ textAlign: 'justify' }}><strong>(d) Joining Fees:</strong> Service Providers will be charged a one-time Joining Fee at the time of joining. This fee may or may not include a kit, depending on the specific terms of the service provider agreement. The Joining Fee is subject to change at the sole discretion of the management.</p>

<h3>5. Provision of Professional Services</h3>
<p style={{ textAlign: 'justify' }}><strong>(a) Independent Contractor:</strong> As a Service Provider, you are an independent contractor and not an employee, agent, or partner of Qdtas Technologies Pvt. Ltd. You are solely responsible for the Professional Services you provide to Users.</p>
<p style={{ textAlign: 'justify' }}><strong>(b) Compliance with Laws:</strong> You agree to comply with all applicable laws, regulations, and licensing requirements when providing Professional Services.</p>
<p style={{ textAlign: 'justify' }}><strong>(c) Service Quality:</strong> You agree to provide Professional Services in a professional and workmanlike manner, consistent with industry standards.</p>
      

<h3>6. Fees and Payment</h3>
<p style={{ textAlign: 'justify' }}><strong>(a) Service Fees:</strong> Qdtas Technologies Pvt. Ltd. will collect fees from Users for the Professional Services you provide through the Platform. Qdtas Technologies Pvt. Ltd. will then remit the applicable portion of these fees to you, after deducting any applicable service fees as outlined in your Service Provider agreement.</p>
<p style={{ textAlign: 'justify' }}><strong>(b) Payment Terms:</strong> Payments will be made to your designated account in accordance with the payment schedule set forth in your Service Provider agreement.</p>
<p style={{ textAlign: 'justify' }}><strong>(c) Taxes:</strong> You are responsible for determining and paying any taxes applicable to the Professional Services you provide. Qdtas Technologies Pvt. Ltd. is not responsible for withholding or paying any taxes on your behalf, including GST. We act solely as an agent for collection of payment in this regard.</p>
<h3>7. Cancellations and Refunds</h3>
<p style={{ textAlign: 'justify' }}><strong>(a) Cancellation Policy:</strong> You agree to our cancellation policy as outlined in your Service Provider agreement. Users may cancel bookings, and you may be required to comply with refund requests in accordance with this policy.</p>
<p style={{ textAlign: 'justify' }}><strong>(b) Refunds:</strong> If a User is entitled to a refund for the Professional Services you provided, Qdtas Technologies Pvt. Ltd. reserves the right to deduct the amount of such refund from any payments due to you or to require you to remit such amounts to Qdtas Technologies Pvt. Ltd.</p>
<h3>8. Intellectual Property</h3>
<p style={{ textAlign: 'justify' }}><strong>(a) License to Qdtas Technologies Pvt. Ltd.:</strong> By providing Professional Services through the Platform, you grant Qdtas Technologies Pvt. Ltd. a worldwide, non-exclusive, royalty-free, transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform any content or materials you provide in connection with the Professional Services.</p>
<p style={{ textAlign: 'justify' }}><strong>(b) Qdtas Technologies Pvt. Ltd. Property:</strong> All intellectual property rights in the Platform and its content are owned by Qdtas Technologies Pvt. Ltd. or its licensors. You agree not to use any intellectual property of Qdtas Technologies Pvt. Ltd. without our prior written consent.</p>

   
<h3>9. Confidentiality</h3>
<p style={{ textAlign: 'justify' }}>You agree to keep confidential any information you receive from Users or Qdtas Technologies Pvt. Ltd. that is marked as confidential or that should reasonably be understood to be confidential. You will not use or disclose such information except as necessary to perform your obligations under these Terms.</p>


<h3>10. Indemnification</h3>
<p style={{ textAlign: 'justify' }}>You agree to indemnify, defend, and hold harmless Qdtas Technologies Pvt. Ltd., its affiliates, officers, directors, employees, and agents, from and against any claims, liabilities, damages, losses, and expenses, including without limitation, reasonable legal and accounting fees, arising out of or in any way connected with your provision of Professional Services or your violation of these Terms.</p>

<h3>11. Limitation of Liability</h3>
<p style={{ textAlign: 'justify' }}>To the maximum extent permitted by law, Qdtas Technologies Pvt. Ltd. shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use or inability to use the Platform; (b) any conduct or content of any third party on the Platform; (c) any content obtained from the Platform; and (d) unauthorized access, use, or alteration of your transmissions or content.</p>

<h3>12. Termination</h3>
<p style={{ textAlign: 'justify' }}>We may terminate or suspend your access to the Platform at any time, without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, your right to use the Platform will immediately cease.</p>

<h3>13. Governing Law and Dispute Resolution</h3>
<p style={{ textAlign: 'justify' }}>These Terms are governed by and construed in accordance with the laws of Pune, India. Any disputes arising out of or relating to these Terms or the use of the Platform shall be resolved through binding arbitration in accordance with the rules of the Arbitration and Conciliation Act, 1966. The place of arbitration shall be Pune, Maharashtra, and the language of arbitration shall be English.</p>


<h3>14. Severability</h3>
<p style={{ textAlign: 'justify' }}>If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will continue to be valid and enforceable to the fullest extent permitted by law.</p>
<h3>15. Entire Agreement</h3>
<p style={{ textAlign: 'justify' }}>These Terms constitute the entire agreement between you and Qdtas Technologies Pvt. Ltd. regarding your use of the Platform and supersede any prior agreements between you and Qdtas Technologies Pvt. Ltd.</p>


<h3>16. Additional Legal Provisions</h3>
<p style={{ textAlign: 'justify' }}><strong>(a) Force Majeure:</strong> Qdtas Technologies Pvt. Ltd. shall not be liable for any failure to perform its obligations hereunder where such failure results from any cause beyond Qdtas Technologies Pvt. Ltd.’s reasonable control, including, without limitation, mechanical, electronic, or communications failure or degradation.</p>
<p style={{ textAlign: 'justify' }}><strong>(b) Waiver and Severability:</strong> The failure of Qdtas Technologies Pvt. Ltd. to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision. If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions of these Terms will remain in full force and effect.</p>
<p style={{ textAlign: 'justify' }}><strong>(c) Assignment:</strong> These Terms and any rights and licenses granted hereunder may not be transferred or assigned by you but may be assigned by Qdtas Technologies Pvt. Ltd. without restriction. Any attempted transfer or assignment in violation hereof shall be null and void.</p>
<p style={{ textAlign: 'justify' }}><strong>(d) Survival:</strong> Upon termination of these Terms, any provision which, by its nature or express terms should survive, will survive such termination or expiration.</p>
<p style={{ textAlign: 'justify' }}><strong>(e) Notices:</strong> All notices or other communications required or permitted to be given under these Terms shall be in writing and delivered to Qdtas Technologies Pvt. Ltd. at the address mentioned below and via email at mentioned below.</p>

<h3>17. Use of Platform by Service Providers</h3>
<p style={{ textAlign: 'justify' }}><strong>(a) Profiles and Offers Must Not Be Fraudulent:</strong> All profiles and service offers must be accurate and not misleading. Any attempt to deceive or mislead Users is strictly prohibited.</p>
<p style={{ textAlign: 'justify' }}><strong>(b) Avoidance of Qdtas Technologies Pvt. Ltd. Fees:</strong> Profiles and offers cannot use techniques to avoid or circumvent Qdtas Technologies Pvt. Ltd. fees. Any attempt to bypass fee structures is a violation of these Terms.</p>
<p style={{ textAlign: 'justify' }}><strong>(c) Sanctions for Violating Rules:</strong> Violating any of the rules for service professionals can result in account suspension or termination.</p>
<h3>18. Prohibited Messaging Practices</h3>
<p style={{ textAlign: 'justify' }}>You will not send messages to other users containing:</p>
<ul style={{ textAlign: 'justify' }}>
    <li>Offers to make national or international money transfers for amounts exceeding the asking price of a service, with intent to request a refund of any portion of the payment; or</li>
    <li>Unsolicited advertising or marketing of a service not offered on the Website or an external website.</li>
</ul>

<h3>19. Service Fees and Additional Charges</h3>
<p style={{ textAlign: 'justify' }}><strong>(a) Joining Qdtas Technologies Pvt. Ltd., opening an Account, posting Services, and viewing posted Services is free.</strong> However, Qdtas Technologies Pvt. Ltd. reserves the right, at its sole discretion, to charge fees to Service Professionals for other services that may be provided in the future.</p>
<p style={{ textAlign: 'justify' }}><strong>(b) Joining Fees:</strong> Service Providers will be charged a one-time Joining Fee at the time of joining. This fee may or may not include a kit, depending on the specific terms of the service provider agreement. The Joining Fee is subject to change at the sole discretion of the management.</p>

      {/* Contact Information */}
      <p style={{ textAlign: 'justify' }}>
        <strong>Contact Information</strong>
        <br />
        If you have any questions about these Terms, please contact us at:
        <br />
        QDTAS Technologies LLP 
        <br />
        The Stellar Business Complex,
        <br />
        Near Poona Bakery,
        <br />
        Wakad, Pune - 411057
        <br />
        services@homelyfservices.com 
        <br />
        +91 98607 4865 / +91 93566 60761 / 020 4422 7377
      </p>

      <p style={{ textAlign: 'justify' }}>
        By using the Platform, you acknowledge that you have read, understood, and agree to be bound by these Terms
        and Conditions.
      </p>
    </div>
  </>
  );
};

export default TermsConditions;
