import React from "react";
import"../node_modules/bootstrap/dist/css/bootstrap.min.css";
import"../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Home from "./Home";
import Service from "./Service";
import About from "./About";
import Contact from "./Contact";
import Download from "./Download";
import Navbar from "./Navbar";
import Footer from "./footer";
import HelpModal from "./HelpModal"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Switch,Route,Redirect} from "react-router-dom";
import TermsConditions from "./TermsConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsConditionsPartners from "./TermsConditionsPartners";
import CancellationRefundPolicy from "./CancellationRefundPolicy";
import CareerPage from "./CareerPage";
const App =() =>{
  return(
    <>
   <Navbar/>
   <div style={{ minHeight: "calc(100vh - 180px)" }}>
    
    <Switch>
   
    <Route exact path="/" component={Home}/>
      <Route exact path="/about" component={About}/>
      <Route exact path="/service" component={Service}/>
      <Route exact path="/contact" component={Contact}/>
      <Route exact path="/Download" component={Download}/>
      <Route exact path="/TermsConditions" component={TermsConditions}/>
      <Route exact path="/PrivacyPolicy"component={PrivacyPolicy}/>
      <Route exact path="/TermsConditionsPartners"component={TermsConditionsPartners}/>
      <Route exact path="/CancellationRefundPolicy"component={CancellationRefundPolicy}/>
      <Route exact path="/CareerPage" component={CareerPage}/>
  <Redirect to="/"/>
  <Route exact path="/HelpModal" component={HelpModal}/>

    </Switch>
   
    </div>

    <Footer/>
    </>
  )
}
export default App;
