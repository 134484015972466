import React from "react";
import { Link } from 'react-router-dom';
import './footer.css';
import { Nav } from "react-bootstrap";
import '@fortawesome/fontawesome-free/css/all.min.css';


const Footer = () => {
    return (
        <div className="footer-dark">
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-md-3 item">
                            <h3>Services</h3>
                            <ul>
                                <li><a href="#">Plumbing</a></li>
                                <li><a href="#">Electrical</a></li>
                                <li><a href="#">Home Cleaning</a></li>
                            </ul>
                        </div>
                        <div className="col-sm-6 col-md-3 item">
                            <h3>About</h3>
                            <ul>
                                <li><a href="https://www.qdtas.com/index.html">Company</a></li>
                                <li><a href="#">Team</a></li>
                                <li><a href="https://www.qdtas.com/career_s.html">Careers</a></li>
                            </ul>
                        </div>
                        <div className="col-md-6 item text">
  <h3>Contact</h3>
  <p>
    <i className="fa fa-phone icon-white"></i> 
    Phone: +91 98607 4865 / +91 93566 60761 / +91 20 4422 7377
  </p>
  <p>
    <i className="fa fa-envelope icon-white"></i> 
    Email: info@homelyfservices.com
  </p>
  <p>
    <i className="fa fa-map-marker-alt icon-white"></i> 
    Address: The Stellar Business Complex, Near Poona Bakery, Wakad, Pune - 411057, India
  </p>
</div>

                    </div>
                    <div className="footer-bottom" style={{ textAlign: 'center' }}>
                        <p style={{ display: 'inline', fontSize: '15px' }}>
                            &copy; 2024 Homelyf Services. All Rights Reserved.
                            &nbsp;&nbsp;
                            <Nav.Link 
                                as={Link} 
                                to="/TermsConditions" 
                                activeClassName="menu_active" 
                                style={{ display: 'inline', fontSize: '15px' }}
                            >
                                Customers Terms & Conditions  
                            </Nav.Link>
                            &nbsp;&nbsp; {/* Adding space and separator */}
                            <Nav.Link 
                                as={Link} 
                                to="/TermsConditionsPartners" 
                                activeClassName="menu_active" 
                                style={{ display: 'inline', fontSize: '15px' }}
                            >
                                Partners Terms & Conditions
                            </Nav.Link>
                            &nbsp; &nbsp;{/* Adding space and separator */}
                            <Nav.Link 
                                as={Link} 
                                to="/PrivacyPolicy" 
                                activeClassName="menu_active" 
                                style={{ display: 'inline', fontSize: '15px' }}
                            >
                                Privacy Policy
                            </Nav.Link>
                            &nbsp;&nbsp; {/* Adding space and separator */}
                            <Nav.Link 
                                as={Link} 
                                to="/CancellationRefundPolicy" 
                                activeClassName="menu_active" 
                                style={{ display: 'inline', fontSize: '15px' }}
                            >
                                Cancellation and Refund Policy
                            </Nav.Link>
                        </p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
