// HelpModal.jsx
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './HelpModel.css'; // Make sure the path is correct

const HelpModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Contact Us</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p><strong>Phone:</strong> +91 98607 4865 / +91 93566 60761 / +91 20 4422 7377</p>
        <p><strong>Email:</strong>info@homelyfservices.com</p>
        <p><strong>Call Support:</strong> Available 24/7</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HelpModal;
