import React from "react";
import './Download.css';

const PrivacyPolicy = () => {
  return (
    <>
     <div
        style={{
          padding: '50px',
          maxWidth: '1200px',
          margin: 'auto',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          color: '#333',
          textAlign: 'justify' // Justified text alignment
        }}
        
      ></div>
      <div style={{ padding: '50px', maxWidth: '1200px', margin: 'auto', fontFamily: 'Arial, sans-serif', fontSize: '14px', color: '#333' }}>
        <h2 style={{ color: '#E62188' }}>Privacy Policy of Homelyf Services</h2>
        <p><strong>Effective Date: 26/09/2024</strong></p>

        <p style={{ textAlign: 'justify' }}>
          Welcome to Homelyf Services (“Homelyf Services” “we,” “us,” or “our”). These Terms and Conditions ("Terms") govern your access to and use of our website (<a href="https://homelyfservices.com/">https://homelyfservices.com/</a>) and our mobile application (‘Homelyf Services’) (collectively, the “Platform”) operated by QDTAS Technologies LLP. By accessing or using the Platform, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Platform.
        </p>

        <h3>1. Definitions</h3>
        <ul>
          <li><strong>“User” or “you”</strong> refers to any individual accessing or using the Platform.</li>
          <li><strong>“Service Providers”</strong> refers to individuals or entities offering professional services through the Platform.</li>
          <li><strong>“Services”</strong> refers to the services provided by Homelyf Services via the Platform.</li>
          <li><strong>“Professional Services”</strong> refers to the services provided by Service Providers through the Platform.</li>
        </ul>

        <h3>2. Acceptance of Terms</h3>
        <p style={{ textAlign: 'justify' }}>
          By accessing or using the Platform, you agree to comply with and be legally bound by these Terms, our Privacy Policy, and all applicable laws and regulations.
        </p>

        <h3>3. Modifications to Terms</h3>
        <p style={{ textAlign: 'justify' }}>
          We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting on the Platform. Your continued use of the Platform after any changes indicates your acceptance of the modified Terms. We recommend you review these Terms periodically.
        </p>

        <h3>4. User Accounts</h3>
        <p style={{ textAlign: 'justify' }}><strong>(a) Registration:</strong> To access certain features of the Platform, you must register and create an account. You agree to provide accurate and complete information during the registration process and to update such information as necessary.</p>
        <p style={{ textAlign: 'justify' }}><strong>(b) Account Security:</strong> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.</p>
        <p style={{ textAlign: 'justify' }}><strong>(c) Account Termination:</strong> We reserve the right to terminate or suspend your account at any time for any reason, including if you breach these Terms.</p>

        <h3>5. Use of the Platform</h3>
        <p style={{ textAlign: 'justify' }}><strong>(a) License:</strong> Homelyf Services grants you a limited, non-exclusive, non-transferable license to access and use the Platform for personal, non-commercial purposes, subject to these Terms.</p>
        <p style={{ textAlign: 'justify' }}><strong>(b) Eligibility Criteria:</strong> Use of the Platform is available only to individuals who are at least 18 years old and can form legally binding contracts under applicable law. You represent, acknowledge, and agree that you are at least 18 years of age, and that: (a) all registration information that you submit is truthful and accurate, (b) you will maintain the accuracy of such information, and (c) your use of the Platform and Services offered through the Platform do not violate any applicable law or regulation.</p>
        <p style={{ textAlign: 'justify' }}><strong>(c) Prohibited Activities:</strong> You agree not to:</p>
        <ul>
          <li>Use the Platform for any unlawful purpose.</li>
          <li>Engage in any activity that interferes with or disrupts the Platform.</li>
          <li>Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity.</li>
          <li>Use any automated system, including without limitation, “robots,” “spiders,” or “offline readers,” to access the Platform.</li>
          <li>Post or transmit any content that is harmful, offensive, or otherwise objectionable.</li>
        </ul>

        <h3>6. Services and Professional Services</h3>
        <p style={{ textAlign: 'justify' }}><strong>(a) Role of Homelyf Services:</strong> Homelyf Services provides a Platform to facilitate the connection between Users and Service Providers. Homelyf Services does not provide the Professional Services itself and is not responsible for the performance of such services by the Service Providers.</p>
        <p style={{ textAlign: 'justify' }}><strong>(b) Booking and Payment:</strong> Users may book Professional Services through the Platform. Payment for these services will be processed through the Platform. Users agree to provide accurate payment information and authorize Homelyf Services to charge the applicable fees to their payment method.</p>
        <p style={{ textAlign: 'justify' }}><strong>(c) Disputes with Service Providers:</strong> Any disputes arising from the Professional Services provided by Service Providers are to be resolved between the User and the Service Provider. Homelyf Services may, at its discretion, facilitate communication between the parties but is not obligated to resolve disputes.</p>

        <h3>7. Intellectual Property</h3>
        <p style={{ textAlign: 'justify' }}><strong>(a) Ownership:</strong> The Platform and its content, including but not limited to text, graphics, logos, and software, are the property of Homelyf Services and are protected by copyright, trademark, and other intellectual property laws.</p>
        <p style={{ textAlign: 'justify' }}><strong>(b) License to Content:</strong> By submitting content to the Platform, you grant Homelyf Services a worldwide, non-exclusive, royalty-free, transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform such content in connection with the Platform and Homelyf Services’ business.</p>

        <h3>8. Privacy</h3>
        <p style={{ textAlign: 'justify' }}>Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and disclose information about you.</p>

        <h3>9. Limitation of Liability</h3>
        <p style={{ textAlign: 'justify' }}>
          To the maximum extent permitted by law, Homelyf Services shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use or inability to use the Platform; (b) any conduct or content of any third party on the Platform; (c) any content obtained from the Platform; and (d) unauthorized access, use, or alteration of your transmissions or content.
        </p>

        <h3>10. Indemnification</h3>
        <p style={{ textAlign: 'justify' }}>
          You agree to indemnify, defend, and hold harmless Homelyf Services, its affiliates, officers, directors, employees, and agents, from and against any claims, liabilities, damages, losses, and expenses, including reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of the Platform or your violation of these Terms.
        </p>

        <h3>11. Termination</h3>
        <p style={{ textAlign: 'justify' }}>
          We may terminate or suspend your access to the Platform at any time, without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, your right to use the Platform will immediately cease.
        </p>

        <h3>12. Governing Law and Dispute Resolution</h3>
        <p style={{ textAlign: 'justify' }}>
          These Terms are governed by and construed in accordance with the laws of Pune, India. Any disputes arising out of or relating to these Terms or the use of the Platform shall be resolved through binding arbitration in accordance with the rules of the Arbitration and Conciliation Act, 1966. The place of arbitration shall be Pune, Maharashtra, and the language of arbitration shall be English.
        </p>

        <h3>13. Severability</h3>
        <p style={{ textAlign: 'justify' }}>
          If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will continue to be valid and enforceable to the fullest extent permitted by law.
        </p>

        <h3>14. Entire Agreement</h3>
        <p style={{ textAlign: 'justify' }}>
          These Terms constitute the entire agreement between you and Homelyf Services regarding the use of the Platform and supersede any prior agreements between you and Homelyf Services.
        </p>

        <h3>15. Additional Legal Provisions</h3>
        <p style={{ textAlign: 'justify' }}>
          (a) <strong>Force Majeure:</strong> Homelyf Services shall not be liable for any failure to perform its obligations hereunder where such failure results from any cause beyond Homelyf Services’ reasonable control, including, without limitation, mechanical, electronic, or communications failure or degradation.
        </p>
        <p>
          (b) <strong>Waiver and Severability:</strong> The failure of Homelyf Services to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision. If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions of these Terms will remain in full force and effect.
        </p>

        <p style={{ textAlign: 'justify' }}>
          <strong>Contact Us:</strong> If you have any questions about these Terms, please contact us at <a href="mailto:support@homelyfservices.com">support@homelyfservices.com</a>.
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
