import React, { useState } from "react";
import "./Contact.css"; // Assuming you have the CSS in a separate file
import HelpModal from './HelpModal'; // Import the HelpModal component

const Contact = () => {
  const [fullName, setFullName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showHelp, setShowHelp] = useState(false); // State for modal visibility

  const handleFullNameChange = (event) => setFullName(event.target.value);
  const handlePhoneNoChange = (event) => setPhoneNo(event.target.value);
  const handleEmailChange = (event) => setEmail(event.target.value);
  const handleMessageChange = (event) => setMessage(event.target.value);

  const formSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("fullName", fullName);
    formData.append("phoneNo", phoneNo);
    formData.append("email", email);
    formData.append("message", message);

    try {
      const response = await fetch("https://usebasin.com/f/c4aa3c0b955a", {
        method: "POST",
        body: formData,
        headers: { Accept: "application/json" },
      });

      if (response.ok) {
        alert(`${fullName}, thank you for getting in touch with us!`);
        setFullName("");
        setPhoneNo("");
        setEmail("");
        setMessage("");
      } else {
        alert("There was an issue submitting the form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Network error. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Open and close modal functions
  const handleOpenHelp = () => setShowHelp(true);
  const handleCloseHelp = () => setShowHelp(false);

  return (
    <div className="contact-section">
      <div className="contact-container">
        {/* Left side: Information */}
        <div className="contact-info">
          <h1 className="info-header">Get in touch</h1>
          <p className="info-subtext">
            <i className="fas fa-phone"></i> Phone: <a href="tel:+91986074865" className="contact-link">+91 98607 4865</a> / 
            <a href="tel:+919356660761" className="contact-link">+91 93566 60761</a> / 
            <a href="tel:+912044227377" className="contact-link">+91 20 4422 7377</a>
          </p>
          <p className="info-subtext">
            <i className="fas fa-envelope"></i> Email: <a href="mailto:info@homelyfservices.com" className="contact-link">info@homelyfservices.com</a>
          </p>
          <p className="info-subtext">
            <i className="fas fa-map-marker-alt"></i> Address: The Stellar Business Complex, Near Poona Bakery, Wakad, Pune - 411057, India
          </p>
        </div>

        {/* Right side: Form */}
        <div className="form-container">
          <form onSubmit={formSubmit}>
            <div className="form-field">
              <label htmlFor="fullName"></label>
              <input
                type="text"
                id="fullName"
                value={fullName}
                onChange={handleFullNameChange}
                placeholder="Enter Full Name"
                required
              />
            </div>
            <div className="form-field">
              <label htmlFor="phoneNo"></label>
              <input
                type="tel"
                id="phoneNo"
                value={phoneNo}
                onChange={handlePhoneNoChange}
                placeholder="Enter Phone Number"
                required
              />
            </div>
            <div className="form-field">
              <label htmlFor="email"></label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter Email Address"
                required
              />
            </div>
            <div className="form-field">
              <label htmlFor="message"></label>
              <textarea
                id="message"
                value={message}
                onChange={handleMessageChange}
                placeholder="Enter Your Message"
                rows="4"
                required
              ></textarea>
            </div>
            <button type="submit" className="submit-button" disabled={isSubmitting}>
              {isSubmitting ? "Connecting..." : "Send Message"}
            </button>
          </form>
        </div>
      </div>

      {/* Help Button */}
      <button className="help-button" onClick={handleOpenHelp}>
        Help
      </button>

      {/* Help Modal */}
      <HelpModal show={showHelp} handleClose={handleCloseHelp} />
    </div>
  );
};

export default Contact;
