import React from "react";
import { NavLink } from "react-router-dom"; // Use "react-router-dom"
import './card.css';

const Card = (props) => {
  const cardStyle = {
    width: props.width || "100%", // Ensure it fits within the column
    height: props.height || "450px", // Default height is now 450px
  };

  const imageStyle = {
    width: "100%", // Make the image take the full width of the card
    height: "220px", // Set a fixed height for the image
    objectFit: "cover", // Ensures the image covers the area while maintaining aspect ratio
  };

  return (
    <div className="col-md-3 col-sm-6 col-12 mb-4"> {/* Adjust column size to fit 4 in one row */}
      <div className="card" style={cardStyle}>
        <img
          src={props.imgsrc}
          className="card-img-top"
          alt={props.title}
          style={imageStyle} // Apply the updated image styles
        />
        <div className="card-body">
          <div className="text-container">
            <h5 className="card-title font-weight-bold">{props.title}</h5>
            <p className="card-text">{props.des}</p>
          </div>
          <div className="text-button-container">
            <NavLink to="/Contact" className="btn btn-custom">
              {props.btnname}
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
