import React, { useState } from 'react';
import './CareerPage.css'; // Import the CSS file
import HelpModal from './HelpModal'; // Import the HelpModal component

const CareerPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    position: '',
    resume: null,
    message: '',
  });

  const [status, setStatus] = useState(''); // State to hold submission status message
  const [fileName, setFileName] = useState(''); // State to hold selected file name
  const [showHelp, setShowHelp] = useState(false); // State to manage HelpModal visibility

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      resume: file,
    }));
    setFileName(file.name); // Set the selected file name
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the resume is selected
    if (!formData.resume) {
      setStatus('Please upload your resume.');
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('position', formData.position);
    formDataToSend.append('resume', formData.resume);
    formDataToSend.append('message', formData.message);

    try {
      const response = await fetch('https://usebasin.com/f/c4aa3c0b955a', {
        method: 'POST',
        body: formDataToSend,
        headers: {
          Accept: 'application/json', // Accept JSON response
        },
      });

      if (response.ok) {
        setStatus('Your details have been sent successfully!');
        setFormData({ name: '', email: '', position: '', resume: null, message: '' });
        setFileName(''); // Clear the file name on success
      } else {
        const errorResponse = await response.json();
        setStatus(`Failed to submit the form: ${errorResponse.message || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setStatus(`An error occurred: ${error.message}`);
    }
  };

  const handleOpenHelp = () => setShowHelp(true); // Function to open the HelpModal
  const handleCloseHelp = () => setShowHelp(false); // Function to close the HelpModal

  return (
    <div className="contact-section">
      <div className="contact-container">
        {/* Left side: Information */}
        <div className="contact-info">
          <h1 className="info-header">Join Our Team Today</h1>
          <p className="info-subtext">Unlock New Opportunities Now</p>
          <p className="info-subtext">Grow, Succeed, and Lead</p>
        </div>

        {/* Right side: Form */}
        <div className="form-wrapper">
          <h1 className="form-header"></h1>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Full Name*"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email Id*"
                required
              />
            </div>
            <div className="form-group">
              <select
                id="position"
                name="position"
                value={formData.position}
                onChange={handleChange}
                required
              >
                <option value="" disabled>Position of Interest*</option>
                <option value="developer">Developer</option>
                <option value="designer">Designer</option>
                <option value="manager">Manager</option>
                <option value="qa">QA Engineer</option>
              </select>
            </div>
            <div className="form-group">
              <input
                type="file"
                id="resume"
                name="resume"
                onChange={handleFileChange}
                required
              />
              {fileName && <span className="file-name">{fileName}</span>} {/* Display selected file name */}
            </div>
            <div className="form-group">
              <textarea
                id="message"
                name="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                placeholder="Additional Message"
              ></textarea>
            </div>
            <button type="submit" className="submit-btn">
              Send <span>➤</span>
            </button>
          </form>
          {status && <div className="status-message">{status}</div>} {/* Status message display */}
          {/* Help Button */}
          <button className="help-button" onClick={handleOpenHelp}>
            Help
          </button>
          {/* Help Modal */}
          <HelpModal show={showHelp} handleClose={handleCloseHelp} />
        </div>
      </div>
    </div>
  );
};

export default CareerPage;
